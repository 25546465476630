@use "../util" as *;

.modal-overlay {
  background: rgb(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 20px;
  overflow: auto;
  animation: fadeIn 0.3s forwards ease;
  .close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    display: none;
    z-index: 5;
    filter: var(--filter-3);
    img {
      width: 15px;
    }
  }
  .separator-under {
    @include separator(142px, 2px, var(--bg-1));
    margin-top: 11px;
  }
}

@media (max-width: $bp-768) {
  .modal-overlay {
    padding: 10px;
    .close-button {
      display: block;
    }
  }
}
