@use "../util" as *;

section.overview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bg-shape {
    transform: rotate(180deg);
    filter: var(--filter-1);
    width: 100%;
    max-width: 1125px;
    position: absolute;
    top: -38px;
    right: 0;
    z-index: -1;
  }
  h3 {
    font-size: $text-normal;
    color: var(--text-1);
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    font-size: $text-sm;
    color: var(--text-2);
    font-weight: 300;
    text-align: center;
    line-height: 25px;
    margin-bottom: 14px;
  }
  .icon {
    width: 69px;
    height: 69px;
    padding: 15px;
    background: var(--bg-4);
    border-radius: $radius-md;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 100%;
    }
  }

  .wrapper-classes {
    display: flex;
    align-items: center;
    gap: 120px;
    article {
      flex: 1;
      &:last-of-type .icon img {
        filter: var(--filter-3);
      }
    }
  }
  .wrapper-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 14px;
    padding: 0 50px;
    margin-top: 20px;
    position: relative;
    .show-more {
      position: absolute;
      font-size: $text-xs;
      font-weight: 500;
      bottom: -50px;
      // background: var(--bg-4);
      color: var(--text-3);
      padding: 5px 10px;
      border-radius: $rounded;
      overflow: hidden;
      &:hover {
        .bg-div {
          transform: translateX(0);
        }
      }
      .bg-div {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--bg-4);
        border-radius: $rounded;
        transform: translateX(-100%);
        transition: 0.3s ease;
      }
      .text {
        z-index: 1;
      }
    }
  }
}

.modal-technologies .modal {
  background-color: var(--bg-1);
  width: 100%;
  max-width: 770px;
  border-radius: $radius-lg;
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  article {
    position: relative;
    z-index: 2;
    margin-bottom: 15px;
    font-size: $text-sm;
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      color: var(--text-1);
      font-size: $text-sm;
      margin-bottom: 5px;
      line-height: 1.2;
    }
    b {
      font-weight: 500;
    }

    b,
    p {
      color: var(--text-3);
      display: inline;
    }
    ul {
      list-style: none;
      position: relative;
      margin-left: 20px;
    }

    li {
      margin-bottom: 5px;
      &::before {
        content: "\2022"; /* \2022 is the CSS Code/unicode for a bullet */
        color: var(--text-3);
        position: absolute;
        left: -12px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .wrapper-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    position: relative;
    z-index: 2;
    .icon {
      width: 50px;
      height: 50px;
      padding: 11px;
      background: var(--bg-4);
      border-radius: $radius-md;
      border: 1px solid var(--shadow-1);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 100%;
      }
    }
  }
  .bg-icons {
    img {
      width: 100%;
      max-width: 100px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      filter: var(--filter-4);
      transform: translate(-50%, -50%);
      &:nth-child(1) {
        transform: translate(-50%, -150%);
      }
      &:nth-child(2) {
        transform: translate(-130%, -20%);
      }
      &:nth-child(3) {
        transform: translate(30%, -20%);
      }
    }
  }
  .separator {
    @include separator(37px, 2px, var(--bg-5));
    align-self: center;
    padding: 1px 0;
    z-index: 5;
    &:first-of-type {
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin: 15px 0;
    }
  }
}

@media (max-width: $bp-992) {
  section.overview {
    .wrapper-classes {
      flex-direction: column;
      gap: 80px;
    }
  }
}

@media (max-width: $bp-600) {
  section.overview {
    .wrapper-icons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0;
      margin-top: 20px;
      .show-more {
        bottom: -40px;
      }
    }
    .icon {
      width: 55px;
      height: 55px;
      padding: 12px;
      border-radius: $radius-rg;
      img {
        width: 100%;
      }
    }
  }
  .modal-technologies .modal {
    padding: 20px;
    .wrapper-icons {
      gap: 10px;
    }
  }
}
