@use "../util" as *;

section.projects {
  display: flex;
  flex-direction: column;
  h3 {
    font-size: $text-normal;
    font-weight: 500;
    color: var(--text-1);
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .separator {
      @include separator(4px, 22px, var(--bg-3));
      position: absolute;
      left: -13px;
    }
  }
  p {
    font-size: $text-sm;
    font-weight: 400;
    color: var(--text-3);
    line-height: 22px;
    margin-right: 30px;
    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  ul {
    list-style-type: none;
    font-size: $text-xs;
    font-weight: 300;
    color: var(--text-3);
    text-align: center;
    line-height: 20px;
  }
  .wrapper-links {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 35px;
    .link {
      font-size: $text-rg;
      font-weight: 500;
      position: absolute;
      a {
        padding: 5px;
      }
      &:first-of-type {
        color: var(--accent-1);
        transform: translate(calc(-50% - 20px), -50%);
        top: 50%;
      }
      &:last-of-type {
        color: var(--text-4);
        transform: translate(calc(50% + 20px), -50%);
        top: 50%;
      }
    }
    .separator {
      @include separator(2px, 22px, var(--bg-5));
    }
  }

  .wrapper-image {
    max-width: 333px;
    height: 194px;
    border-radius: $radius-rg;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0px 5px 5px rgb(0, 0, 0, 0.25);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .wrapper-section {
    flex: 1;

    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: max-content;
    }
  }
  .wrapper-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 14px;
    margin-left: 20px;
    margin-right: 15px;
    .icon {
      width: 38px;
      height: 38px;
      padding: 9px;
      background: var(--bg-4);
      border: 1px solid var(--bg-5);
      border-radius: $radius-md;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 100%;
      }
    }
  }

  .project {
    display: flex;
    align-items: center;
    max-width: 850px;
    margin-bottom: 100px;
    position: relative;
    .read-more, .read-more-button {
      display: none;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-child(even) {
      .bg-shape {
        filter: var(--filter-2);
        transform: rotate(180deg);
        right: unset;
        left: -12px;
      }
      .wrapper-section {
        &:nth-child(1) {
          order: 3;
        }
        &:nth-child(2) {
          order: 2;
          margin-right: 30px;
        }
      }
      .wrapper-icons {
        order: 1;
        margin-left: 0;
        margin-right: 20px;
      }
      p {
        margin-right: 0;
      }
    }
  }
  .bg-shape {
    position: absolute;
    z-index: -1;
    filter: var(--filter-1);
    width: 100%;
    max-width: 660px;
    height: 375px;
    right: 12px;
    top: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: $bp-992) {
  section.projects {
    p {
      font-size: $text-xs;
    }
    ul {
      font-size: $text-2xs;
    }
  }
}

@media (max-width: $bp-768) {
  section.projects {
    .project {
      flex-direction: column;
      .read-more-button {
        display: block;
        font-size: $text-sm;
        color: var(--text-3);
        font-weight: 500;
        letter-spacing: 0.02em;
      }

      .wrapper-section {
        &:nth-child(1) {
          order: 3;
          display: flex;
          flex-direction: column;
          h3 {
            justify-content: center;
            margin-top: 15px;
            .separator {
              display: none;
            }
          }
          p {
            text-align: center;
            margin-right: 0;
          }
          .read-more-text {
            display: none;
          }
          .read-more {
            margin: 15px 0;
          }
          &.active {
            .read-more {
              display: block;
            } 
            .read-more-text {
              display: block;
            }
          }
        }
        &:nth-child(2) {
          order: 1;
          margin-right: 0;
          ul {
            display: none;
          }
        }
      }
      .wrapper-icons {
        order: 2;;
      }
      &:nth-child(even) {
        .bg-shape {
        }
        .wrapper-section {
          &:nth-child(1) {
            order: 3;
          }
          &:nth-child(2) {
            order: 1;
            margin-right: 0;
          }
        }
        .wrapper-icons {
          order: 2;
          margin-right: 0px;
        }
      }
    }
    .wrapper-icons {
      flex-direction: row;
    }
  }
}
