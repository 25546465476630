@use "../util" as *;

header {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  .magnet {
    padding: 8px;
  }
  .header-center {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: $container;
  }
  .desktop-nav {
    z-index: 10;
    nav {
      display: flex;
      gap: 28px;
      background-color: var(--bg-2);
      padding: 18px 22px;
      transition: border-radius 0.6s ease;
      border-radius: 10px 10px 25px 25px;
    }
  }
  .desktop-nav.scrolled nav {
    border-radius: 35px 35px 35px 35px;
  }
  .github {
    transition: transform 0.3s ease;
    margin-top: 9px;
    img {
      width: 35px;
    }
  }
  .theme-buttons {
    margin-top: 17px;
    .outer {
      width: 38px;
    }
    .inner {
      position: absolute;
      transition: all 0.3s ease;
      padding: 0 4px;
      transform: translateX(calc(100% - 4px));
    }
    .change-theme-button.active {
      .inner {
        transform: translateX(0);
      }
    }
  }
  .toggle-mobile-menu {
    display: none;
  }
  .theme-buttons,
  .github {
    .wrapper-image {
      display: flex;
      align-items: center;
      position: relative;
      .bg-div {
        background-color: var(--bg-1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: $rounded;
      }
    }
    img {
      filter: var(--filter-3);
      z-index: 1;
    }
  }
}

@media (max-width: $bp-768) {
  header {
    padding-bottom: 10px;
    .desktop-nav {
      display: none;
    }
    .github {
      display: none;
    }
    .theme-buttons {
      right: unset;
      left: 10px;
    }
  }
}
